import { useCallback, useEffect } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import styles from "./app.module.css";

const appName = "Game"; //enter your app file name from public/unitybuild folder

const App = () => {
  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    addEventListener,
    removeEventListener,
  } = useUnityContext({
    loaderUrl: "/unitybuild/" + appName + ".loader.js",
    dataUrl: "/unitybuild/" + appName + ".data",
    frameworkUrl: "/unitybuild/" + appName + ".framework.js",
    codeUrl: "/unitybuild/" + appName + ".wasm",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const handleOpenUrl = useCallback((url: string) => {
    console.log(url);
    // window.open(url, "_blank", "noreferrer");
    window.open(url, "_self");
  }, []);

  useEffect(() => {
    addEventListener("OpenUrl", handleOpenUrl);
    return () => {
      removeEventListener("OpenUrl", handleOpenUrl);
    };
  }, [handleOpenUrl, addEventListener, removeEventListener]);

  return (
    <div className={styles.container}>
      <div className={styles.unityWrapper}>
        {isLoaded === false && (
          <div className={styles.loadingBar}>
            <div
              className={styles.loadingBarFill}
              style={{ width: loadingProgression * 396 }}
            />
          </div>
        )}
        <Unity
          unityProvider={unityProvider}
          style={{ display: isLoaded ? "block" : "none" }}
        />
      </div>
    </div>
  );
};

export { App };
